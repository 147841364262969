<template>
  <svg
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.2025 2.17499C21.2025 2.09999 21.2025 2.02499 21.1103 1.94999V1.87499C21.1103 1.79999 21.0181 1.72499 20.926 1.64999C20.8799 1.61249 20.8338 1.59374 20.7877 1.57499C20.7416 1.55624 20.6955 1.53749 20.6494 1.49999H20.5572C20.465 1.49999 20.3729 1.49999 20.2807 1.42499V1.49999C20.1885 1.49999 20.0963 1.49999 20.0041 1.57499L1.56717 6.82499C1.19843 6.89999 0.921875 7.12499 0.921875 7.49999C0.921875 7.79999 1.10624 8.09999 1.47498 8.24999L9.40287 11.1L12.9059 17.55C13.0903 17.85 13.3668 18 13.7356 18C14.1043 18 14.473 17.775 14.5652 17.475L21.0181 2.47499C21.2025 2.39999 21.2025 2.32499 21.2025 2.17499ZM16.7776 4.04999L9.95598 9.59999L4.33271 7.57499L16.7776 4.04999ZM11.2466 10.65L13.7356 15.225L18.0682 5.09999L11.2466 10.65Z"
      fill="#F7F7F5"
    />
    <mask
      id="mask0_185_11862"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="22"
      height="17"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.2025 2.17499C21.2025 2.09999 21.2025 2.02499 21.1103 1.94999V1.87499C21.1103 1.79999 21.0181 1.72499 20.926 1.64999C20.8799 1.61249 20.8338 1.59374 20.7877 1.57499C20.7416 1.55624 20.6955 1.53749 20.6494 1.49999H20.5572C20.465 1.49999 20.3729 1.49999 20.2807 1.42499V1.49999C20.1885 1.49999 20.0963 1.49999 20.0041 1.57499L1.56717 6.82499C1.19843 6.89999 0.921875 7.12499 0.921875 7.49999C0.921875 7.79999 1.10624 8.09999 1.47498 8.24999L9.40287 11.1L12.9059 17.55C13.0903 17.85 13.3668 18 13.7356 18C14.1043 18 14.473 17.775 14.5652 17.475L21.0181 2.47499C21.2025 2.39999 21.2025 2.32499 21.2025 2.17499ZM16.7776 4.04999L9.95598 9.59999L4.33271 7.57499L16.7776 4.04999ZM11.2466 10.65L13.7356 15.225L18.0682 5.09999L11.2466 10.65Z"
        fill="#F7F7F5"
      />
    </mask>
    <g mask="url(#mask0_185_11862)">
      <rect
        width="22.1243"
        height="18"
        fill="#E0E0E0"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'MessageIcon',
}
</script>
