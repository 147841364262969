<script>
import { SHOW_DOWNLOADS_NAV_INDICATOR } from '@/store/modules/notification.module'

export default {
    methods: {
     notifyDownloadsNavMenu() {
        this.$store.commit(SHOW_DOWNLOADS_NAV_INDICATOR)
     },
    },
}
</script>
