<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.98995 8.98995L2.4 8.5799L2.4 8V5C2.4 3.01178 4.01177 1.4 5.99999 1.4C7.98822 1.4 9.6 3.01177 9.6 5L9.6 8L9.6 8.5799L10.01 8.98995L10.6 9.5799V10.5C10.6 10.5552 10.5552 10.6 10.5 10.6H1.5C1.44477 10.6 1.4 10.5552 1.4 10.5V9.5799L1.98995 8.98995ZM12 10.5L12 9.41422C12 9.149 11.8946 8.89465 11.7071 8.70711L11 8L11 5C11 2.23858 8.76142 -3.33786e-06 5.99999 0C3.23857 2.38419e-06 0.999998 2.23858 0.999998 5L0.999999 8L0.292892 8.70711C0.105357 8.89465 0 9.149 0 9.41422V10.5C0 11.3284 0.671573 12 1.5 12H5C5 12.5523 5.44773 13 6 13C6.55227 13 7 12.5523 7 12H10.5C11.3284 12 12 11.3284 12 10.5Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'BellIcon',
}
</script>
