<template>
  <div class="w-100 logo-container d-flex flex-column">
    <div class="d-flex justify-content-center">
      <b-img
        :src="brandDetails.logo || getDefaultProductImage(400, 400)"
        alt="Brand Logo"
        class="brand-logo"
      />
    </div>
    <div class="d-flex flex-column">
      <div class="brand-name mt-1 text-center">
        {{ brandDetails.entityName }}
      </div>
      <b-link
        v-if="brandDetails.websiteLink"
        class="website-link"
        target="_blank"
        :href="getLinkWithhttp(brandDetails.websiteLink)"
      >
        {{ brandDetails.websiteLink }}
      </b-link>
      <div class="mt-20-px text-center">
        <span>
          <location-icon />
        </span>
        <span class="ml-5-px">{{ brandDetails.country }}</span>
      </div>
      <div
        v-if="brandDetails.departments && brandDetails.departments.length"
        class="elements-center flex-wrap mt-24-px brand-departments"
      >
        <b-badge
          v-for="department in brandDetails.departments.slice(0, 3)"
          :key="department"
          class="text-nowrap ml-50 mt-25"
          variant="custom-secondary"
        >
          {{ department }}
        </b-badge>
      </div>
      <div
        v-if="isViewByAdmin"
        class="d-flex justify-content-center  my-1"
      >
        <b-button
          variant="secondary"
          class="font-weight-bold d-flex align-items-center"
          :href="'/?magicLink=' + brandDetails.token"
        >
          <span>Access Brand</span>
          <feather-icon
            icon="ArrowRightIcon"
            size="18"
            class="ml-1"
          />

        </b-button>
      </div>
      <div
        v-if="isRetailer"
        class="mt-20-px d-flex justify-content-center"
        :class="{'justify-content-md-between': monthsWithCollection.length}"
      >
        <b-button
          variant="custom-secondary"
          class="font-weight-bold book-meeting-btn"
          :block="monthsWithCollection.length"
          @click="openScheduleMeetingModel"
        >
          Book a Meeting
        </b-button>
        <b-button
          v-if="monthsWithCollection.length"
          :variant="isSubscribed ? 'custom-light': 'custom-primary'"
          class="elements-center font-weight-bold mt-0 book-meeting-btn"
          :class="{'ml-8-px': monthsWithCollection.length}"
          block
          @click="onHandleSubscription"
        >
          <bell-off-icon v-if="isSubscribed" />
          <bell-icon v-else />
          <span class="ml-50"> {{ isSubscribed ? 'Unfollow Brand': 'Follow Brand' }} </span>
        </b-button>
      </div>
      <div class="prices mt-20-px">
        <div
          v-if="brandDetails.avgWholesalePrice"
          class="d-flex justify-content-between"
        >
          <span>Wholesale prices</span>
          <span>
            {{ brandDetails.avgWholesalePrice }}
          </span>
        </div>
        <div
          v-if="brandDetails.avgRetailPrice"
          class="d-flex justify-content-between mt-6-px"
        >
          <span>Retail prices</span>
          <span>
            {{ brandDetails.avgRetailPrice }}
          </span>
        </div>
        <div
          v-if="brandDetails.minimumOrderValue"
          class="d-flex justify-content-between mt-6-px"
        >
          <span>Minimum Order Value</span>
          <span>
            {{ formatCurrency(brandDetails.minimumOrderValue, brandDetails.currency) }}
          </span>
        </div>
      </div>
      <div
        v-if="!isViewByAdmin && monthsWithCollection.length"
        class="mt-24-px collection-schedule"
        @click="openScheduleCollectionModel"
      >
        Collection Schedule
      </div>
      <div
        v-if="hasSocialLinks"
        class="brand-headings mt-24-px"
      >
        Social Links
        <div class="d-flex mt-75 mb-50">
          <b-link
            v-if="brandDetails.instagramLink"
            class="mr-1"
            target="_blank"
            :href="getLinkWithhttp(brandDetails.instagramLink)"
          >
            <instagram-icon />
          </b-link>
          <b-link
            v-if="brandDetails.facebookLink"
            class="mr-1"
            target="_blank"
            :href="getLinkWithhttp(brandDetails.facebookLink)"
          >
            <facebook-icon />
          </b-link>
          <b-link
            v-if="brandDetails.linkedinLink"
            target="_blank"
            :href="getLinkWithhttp(brandDetails.linkedinLink)"
          >
            <linked-in-icon />
          </b-link>
        </div>
      </div>
      <div v-if="brandDetails.storeDescription">
        <b-card-text class="brand-headings mb-25 mt-24-px">
          About Brand
        </b-card-text>
        <b-card-text class="overflow-scroll">
          <template v-if="isFullDescription">
            {{ brandDetails.storeDescription }}
          </template>
          <template v-else>
            {{ textTruncate(brandDetails.storeDescription) }}
          </template>
          <span v-if="brandDetails.storeDescription.length > 150">
            <span
              class="read-more"
              @click="isFullDescription = !isFullDescription"
            >Read {{ isFullDescription ? 'Less' : 'More' }}</span>
          </span>
        </b-card-text>
      </div>
      <schedule-meeting :brand-details="brandDetails" />
      <schedule-collection
        :user="brandDetails"
        :months-with-collection="monthsWithCollection"
        :is-subscribed="isSubscribed"
        @onHandleSubscription="onHandleSubscription"
      />
    </div>
  </div>
</template>

<script>
import { utils, constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { getDefaultProductImage } from '@/@core/utils/utils'
import {
 BButton, BImg, BBadge, BLink, BCardText,
} from 'bootstrap-vue'
import LocationIcon from '@/@core/assets/svg-components/LocationIcon.vue'
import BellIcon from '@/@core/assets/svg-components/BellIcon.vue'
import store from '@/store'
import {
  FOLLOW_OR_UNFOLLOW_BRAND,
  GET_SCHEDULE,
} from '@/store/modules/brand.module'
import InstagramIcon from '@/@core/assets/svg-components/InstagramIcon.vue'
import FacebookIcon from '@/@core/assets/svg-components/FacebookIcon.vue'
import LinkedInIcon from '@/@core/assets/svg-components/LinkedInIcon.vue'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'

import BellOffIcon from '@/@core/assets/svg-components/BellOffIcon.vue'
import { getLinkWithhttp } from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import UserRoleMixin from '../../UserRoleMixin.vue'
import ScheduleCollection from './ScheduleCollection.vue'
import ScheduleMeeting from './ScheduleMeeting.vue'

const { formatCurrency } = utils

const { MONTHS } = c

export default {
  components: {
    BButton,
    BImg,
    BBadge,
    BLink,
    ScheduleMeeting,
    LocationIcon,
    BellIcon,
    ScheduleCollection,
    InstagramIcon,
    FacebookIcon,
    LinkedInIcon,
    BCardText,
    BellOffIcon,
    FeatherIcon,
  },
  mixins: [UserRoleMixin],
  props: {
    brandDetails: {
      type: Object,
      default: () => {},
    },
    isViewByAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      getDefaultProductImage,
      formatCurrency,
      isFullDescription: false,
      scheduledCollectionId: null,
      isSubscribed: false,
      monthsWithCollection: [],
      getLinkWithhttp,
    }
  },
  computed: {
    hasSocialLinks() {
      return (
        this.brandDetails.instagramLink
        || this.brandDetails.facebookLink
        || this.brandDetails.linkedinLink
      )
    },
  },
  mounted() {
    if (!this.isGuest) {
      this.getSubscription()
    }
  },
  methods: {
    openScheduleMeetingModel() {
      this.$bvModal.show('schedule-meeting')
      analytics.track(constants.TRACKS.ACTIONS.RETAILER_CLICKS_BOOK_A_MEETING)
    },
    openScheduleCollectionModel() {
      this.$bvModal.show('schedule_collection')
    },
    getSubscription() {
      const params = {
        brandId: this.$route?.params?.brandId,
      }
      this.$store
        .dispatch(GET_SCHEDULE, params)
        .then(res => {
          const data = res.data.data
          const schedules = res.data?.data?.schedules || []
          this.scheduledCollectionId = data?._id || ''
          this.isSubscribed = data?.isSubscribed || false
          if (schedules.length) {
            Object.values(MONTHS).forEach(month => {
              this.monthsWithCollection.push({
                ...month,
                ...(schedules.find(
                  schedule => month.LABEL === schedule.month,
                ) || {}),
              })
            })
          }
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
    textTruncate(string) {
     return string.substr(0, 150)
    },
    onHandleSubscription() {
      if (this.scheduledCollectionId) {
        store.dispatch(FOLLOW_OR_UNFOLLOW_BRAND, this.scheduledCollectionId)
          .then(res => {
            this.isSubscribed = !this.isSubscribed
            apiToastSuccess(res?.data?.message || 'Subscription updated')
          })
          .catch(err => {
            apiToastWarning(err)
          })
      }
    },
  },
}
</script>
