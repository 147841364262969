<template>
  <div>
    <!-- Download catalogue icon -->
    <!-- download catalogue modal -->
    <b-modal
      :id="`download_catalog_${collection._id}`"
      centered
      title="Download Catalogue"
      hide-footer
    >
      <div class="mb-2 order-download-modal">
        <div class="mt-1">
          <div class="input-container w-100">
            <label
              :key="downloadOption.XLSX"
              class="selected-color w-100"
            >
              <input
                type="radio"
                name="exportType"
                :value="downloadOption.XLSX"
                :checked="downloadOption.XLSX === exportType"
                @change="onSelectType"
              >
              <span class="text-nowrap mb-1">
                <b-card-text class="ml-1">
                  Download Excel
                </b-card-text>
              </span>
            </label>
            <!--  Disabled CSV option -->
            <!-- <label
              :key="downloadOption.CSV"
              class="selected-color w-100"
            >
              <input
                type="radio"
                name="exportType"
                :value="downloadOption.CSV"
                :checked="downloadOption.CSV === exportType"
                @change="onSelectType"
              >
              <span class="text-nowrap mb-1">
                <b-card-text class="ml-1">
                  {{ isRetailer ? 'Download without Images as csv' : 'CSV' }}
                </b-card-text>
              </span>
            </label> -->
            <b-button
              class="float-right my-1 w-100"
              variant="primary"
              :disabled="downloading"
              @click="downloadCatalogue"
            >
              {{ downloading ? 'Downloading...' : 'Start Download' }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
 BCardText, BButton, BModal, VBModal, VBTooltip,
} from 'bootstrap-vue'
import constants, { DOWNLOAD_TYPES, FILE_FORMATS } from '@/constants'
import store from '@/store'
import { apiToastSuccess } from '@/@core/utils/toast'
import { DOWNLOAD_COLLECTION } from '@/store/modules/collection.module'
import NotificationMixin from '@/views/mixins/NotificationMixin.vue'

const downloadOption = constants.DOWNLOAD_OPTIONS.CATALOG
export default {
  name: 'DownloadCatalog',
  components: {
    BButton,
    BModal,
    BCardText,
  },
  directives: {
    'v-b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [NotificationMixin],
  props: {
    collection: {
        type: Object,
        default() {
            return null
        },
    },
  },
  data() {
    return {
      downloading: false,
      downloadOption,
      exportType: downloadOption.XLSX,
    }
  },
  computed: {
    isRetailer() {
      return store.getters.isRetailer
    },
  },
  methods: {
    onSelectType(event) {
      this.exportType = event.target.value
    },
    downloadCatalogue() {
      this.downloading = true
      const payload = {
        downloadType: DOWNLOAD_TYPES.COLLECTION_EXPORT.VALUE,
        respectiveId: this.collection._id,
        fileType: FILE_FORMATS.XLSX,
      }
        this.$store.dispatch(DOWNLOAD_COLLECTION, { payload })
        .then(res => {
            apiToastSuccess(res.data.message)
            this.downloading = false
            this.$bvModal.hide(`download_catalog_${this.collection._id}`)
            this.notifyDownloadsNavMenu()
          })
          .catch(() => {
            this.downloading = false
        })
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';

</style>
