<template>
  <div v-if="isLoaded">
    <div class="brand-details font-manrope">
      <div
        class="d-flex"
      >
        <b-img
          class="banner-image"
          :src="(brandDetails.banner && brandDetails.banner[0]) || defaultBanner"
        />
      </div>

      <!-- details card -->
      <div class="detail-card">
        <div
          id="intro_brand_info"
          class="details-container"
        >
          <brand-preview-card :brand-details="brandDetails" />
        </div>
        <brand-collections />
      </div>
    </div>
    <tool-kit :toolkit-page="TOOLKIT_PAGE.BRAND_PROFILE" />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { apiToastWarning } from '@/@core/utils/toast'
import { FETCH_BRAND_DETAIL } from '@/store/modules/brand.module'
import analytics from '@/@core/utils/analytics'
import constants, { TOOLKIT_PAGE } from '@/constants'
import ToolKit from '@/@core/components/tool-kit/ToolKit.vue'
import BrandPreviewCard from './BrandPreviewCard.vue'
import BrandCollections from './BrandCollections.vue'
import UserRoleMixin from '../../UserRoleMixin.vue'

export default {
  name: 'BrandDetail',
  components: {
    BImg,
    BrandPreviewCard,
    BrandCollections,
    ToolKit,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      brandId: this.$route.params.brandId,
      brandDetails: {},
      isLoaded: false,
      defaultBanner: require('@/assets/images/banner/default-banner.jpg'),
      TOOLKIT_PAGE,
    }
  },
  watch: {
    '$route.params.brandId': {
      handler(brandId) {
        // route changes
        if (brandId !== this.brandId) {
          this.brandId = brandId
          this.fetchBrandDetails()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchBrandDetails()
  },
  methods: {
    fetchBrandDetails() {
      this.$store.dispatch(FETCH_BRAND_DETAIL, this.brandId)
        .then(res => {
          const brand = res.data.data
          this.brandDetails = brand
          // update brand name on breadcrumb
          this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.map(
            item => {
              if (item.dynamic === true) {
                item.brandName = brand.entityName || 'New Brand'
              }
              return item
            },
)
          this.isLoaded = true
          if (this.isRetailer) {
            analytics.track(constants.TRACKS.PAGES.RETAILER_OPENS_BRANDS_PROFILE, { entityName: brand.entityName, id: brand._id })
          }
        })
        .catch(err => {
          apiToastWarning(err)
          this.isLoaded = true
        })
    },
  },
}
</script>
<style lang="scss">
@import '~@core/scss/base/pages/app-brand-preview.scss';
</style>
