<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 1.47059V18.5294C20 18.9194 19.8451 19.2935 19.5693 19.5693C19.2935 19.8451 18.9194 20 18.5294 20H1.47059C1.08056 20 0.706514 19.8451 0.430725 19.5693C0.154937 19.2935 0 18.9194 0 18.5294L0 1.47059C0 1.08056 0.154937 0.706514 0.430725 0.430725C0.706514 0.154937 1.08056 0 1.47059 0L18.5294 0C18.9194 0 19.2935 0.154937 19.5693 0.430725C19.8451 0.706514 20 1.08056 20 1.47059ZM5.88235 7.64706H2.94118V17.0588H5.88235V7.64706ZM6.14706 4.41177C6.14861 4.18929 6.10632 3.96869 6.02262 3.76255C5.93891 3.55642 5.81542 3.36879 5.65919 3.21039C5.50297 3.05198 5.31708 2.92589 5.11213 2.83933C4.90718 2.75277 4.68718 2.70742 4.46471 2.70588H4.41177C3.95934 2.70588 3.52544 2.88561 3.20552 3.20552C2.88561 3.52544 2.70588 3.95934 2.70588 4.41177C2.70588 4.86419 2.88561 5.29809 3.20552 5.61801C3.52544 5.93792 3.95934 6.11765 4.41177 6.11765C4.63426 6.12312 4.85565 6.0847 5.06328 6.00458C5.27092 5.92447 5.46074 5.80422 5.62189 5.65072C5.78304 5.49722 5.91237 5.31346 6.00248 5.10996C6.09259 4.90646 6.14172 4.6872 6.14706 4.46471V4.41177ZM17.0588 11.3412C17.0588 8.51177 15.2588 7.41177 13.4706 7.41177C12.8851 7.38245 12.3021 7.50716 11.7799 7.77345C11.2576 8.03974 10.8143 8.43832 10.4941 8.92941H10.4118V7.64706H7.64706V17.0588H10.5882V12.0529C10.5457 11.5403 10.7072 11.0315 11.0376 10.6372C11.3681 10.2429 11.8407 9.99489 12.3529 9.94706H12.4647C13.4 9.94706 14.0941 10.5353 14.0941 12.0176V17.0588H17.0353L17.0588 11.3412Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'LinkedInIcon',
}
</script>
