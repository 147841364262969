<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="15.25"
      fill="white"
    />
    <path
      d="M16.3636 22.4735H10.7489C10.4055 22.4735 10.0762 22.3363 9.83333 22.0922C9.59052 21.848 9.4541 21.5168 9.4541 21.1715L9.46058 12.058C9.46058 11.3419 10.0303 10.756 10.7489 10.756H11.3963V10.1051C11.3963 9.74704 11.6876 9.4541 12.0437 9.4541C12.3997 9.4541 12.691 9.74704 12.691 10.1051V10.756H17.8702V10.1051C17.8702 9.74704 18.1615 9.4541 18.5175 9.4541C18.8736 9.4541 19.1649 9.74704 19.1649 10.1051V10.756H19.8123C20.5244 10.756 21.1071 11.3419 21.1071 12.058V15.9638H19.8123V14.6619H10.7489V21.1715H16.3636V22.4735Z"
      fill="#16ACD9"
    />
    <path
      d="M19.6327 22.5462C19.0565 22.5462 18.4933 22.3744 18.0142 22.0525C17.5351 21.7307 17.1617 21.2731 16.9412 20.7379C16.7207 20.2026 16.6631 19.6136 16.7755 19.0454C16.8879 18.4771 17.1653 17.9552 17.5728 17.5455C17.9802 17.1358 18.4993 16.8568 19.0644 16.7438C19.6295 16.6308 20.2153 16.6888 20.7476 16.9105C21.2799 17.1322 21.7349 17.5077 22.055 17.9894C22.3751 18.4711 22.546 19.0375 22.546 19.6169C22.546 20.3938 22.2391 21.1389 21.6927 21.6882C21.1464 22.2376 20.4054 22.5462 19.6327 22.5462ZM19.6327 17.4198C19.2006 17.4198 18.7782 17.5487 18.4188 17.7901C18.0595 18.0315 17.7795 18.3746 17.6141 18.7761C17.4487 19.1776 17.4055 19.6193 17.4898 20.0455C17.5741 20.4717 17.7822 20.8631 18.0877 21.1704C18.3933 21.4776 18.7826 21.6869 19.2065 21.7717C19.6303 21.8564 20.0696 21.8129 20.4689 21.6466C20.8681 21.4804 21.2094 21.1988 21.4494 20.8375C21.6895 20.4762 21.8177 20.0514 21.8177 19.6169C21.8177 19.0342 21.5875 18.4754 21.1777 18.0633C20.768 17.6513 20.2122 17.4198 19.6327 17.4198Z"
      fill="#16ACD9"
    />
    <path
      d="M20.2117 20.7154L19.2686 19.767V18.1522H19.9969V19.4667L20.7252 20.1991L20.2117 20.7154Z"
      fill="#16ACD9"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="15.25"
      stroke="#16ACD9"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: 'CalenderCirlcleIcon',
}
</script>
