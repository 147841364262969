<template>
  <div>
    <b-modal
      id="schedule-meeting"
      ref="schedule-meeting"
      modal-class="custom-modal-variant meeting-model"
      centered
      hide-header-close
      hide-header
      hide-footer
      @show="showModal"
    >
      <div>
        <div class="pb-1 border-bottom d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <calender-circle-icon />
            <span class="custom-modal-heading color-dark-blue ml-8-px">Schedule a Meeting with {{ brandDetails.entityName }}</span>
          </div>
          <feather-icon
            class="close-x-icon"
            icon="XIcon"
            size="24"
            @click="$bvModal.hide('schedule-meeting')"
          />
        </div>
        <div class="sub-header">
          We will send your meeting request to {{ brandDetails.entityName }} with details you included this form.
        </div>
        <div class="form-container">
          <validation-observer
            ref="brandAboutForm"
            #default="{ invalid }"
          >
            <b-form-group class="brand-profile-input">
              <template>
                <div class="input-label">
                  <span>
                    Meeting Objective
                  </span>
                  <span class="text-danger">*</span>
                </div>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Meeting Objective"
                vid="message-objective"
                rules="required|min:10"
              >
                <b-form-textarea
                  id="message-objective"
                  v-model="meetingObjective"
                  placeholder="Type in your meeting objective"
                  rows="5"
                />
                <span
                  v-if="errors.length"
                  class="text-danger font-small-4"
                >
                  {{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
            <div class="elements-center my-2">
              <b-button
                class="mr-1 font-weight-bolder px-4"
                variant="outline-info"
                @click="$bvModal.hide('schedule-meeting')"
              >
                Cancel
              </b-button>
              <b-button
                class="px-4"
                variant="info"
                :disabled="invalid || isScheduling"
                @click="scheduleMeeting"
              >
                {{ isScheduling ? 'Scheduling....' : 'Schedule a meeting' }}
              </b-button>
            </div>
          </validation-observer>
        </div>

      </div>
    </b-modal>

    <b-modal
      id="schedule-meeting-success"
      modal-class="custom-modal-variant meeting-model"
      centered
      hide-header-close
      hide-header
      hide-footer
      @show="showModal"
    >
      <div>
        <div class="pb-1 border-bottom d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <calender-circle-icon />
            <span class="custom-modal-heading color-dark-blue ml-8-px">Schedule a Meeting with {{ brandDetails.entityName }}</span>
          </div>
          <feather-icon
            class="close-x-icon"
            icon="XIcon"
            size="24"
            @click="$bvModal.hide('schedule-meeting-success')"
          />
        </div>
        <div>
          <div class="w-100 d-flex flex-column justify-content-center">
            <div class="w-100 d-flex justify-content-center mt-2">
              <circle-tick-icon />
            </div>
            <div class="success-header">
              The brand has been notified.
            </div>
            <div class="success-message">
              The brand has been notified, and we will get back to you shortly.
            </div>
          </div>
          <div class="elements-center my-2">
            <b-button
              class="font-weight-bolder px-4"
              variant="info"
              @click="$bvModal.hide('schedule-meeting-success')"
            >
              Done
            </b-button>
          </div>
        </div>

      </div>
    </b-modal>
  </div>

</template>

<script>
import { apiToastWarning } from '@/@core/utils/toast'
import store from '@/store'
import { SCHEDULE_MEETING } from '@/store/modules/notification.module'
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import CalenderCircleIcon from '@/@core/assets/svg-components/CalenderCircleIcon.vue'
import CircleTickIcon from '@/@core/assets/svg-components/CircleTickIcon.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BButton,
    ValidationObserver,
    ValidationProvider,
    CalenderCircleIcon,
    CircleTickIcon,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    brandDetails: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isScheduling: false,
      meetingObjective: '',
      brandId: this.$route?.params?.brandId || '',
    }
  },
  methods: {
    scheduleMeeting() {
      const payload = {
        meetingObjective: this.meetingObjective,
        brandId: this.brandId,
      }

      this.isScheduling = true
      store
        .dispatch(SCHEDULE_MEETING, payload)
        .then(() => {
          this.$bvModal.hide('schedule-meeting')
          this.isScheduling = false
          this.$bvModal.show('schedule-meeting-success')
        })
        .catch(err => {
          this.isScheduling = false
          apiToastWarning(err)
        })
    },
    showModal() {
        this.meetingObjective = ''
    },
  },
}
</script>
