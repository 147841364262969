<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 4.47716 15.5229 7.62939e-06 10 7.62939e-06C4.47715 7.62939e-06 0 4.47716 0 10C0 14.9913 3.65686 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93043 3.90626 12.2147 3.90626C13.3087 3.90626 14.4531 4.10157 14.4531 4.10157V6.56251H13.1922C11.9499 6.56251 11.5625 7.33335 11.5625 8.12417V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3431 19.1283 20 14.9913 20 10Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon',
}
</script>
