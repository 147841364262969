<template>
  <div class="brand-page">
    <b-tabs
      v-if="isLoaded"
      class="collection-tabs"
      nav-wrapper-class="collection-list"
    >
      <b-tab title="Active Collections">
        <div v-if="liveCollections.length">
          <section class="brand-collections">
            <collection-card
              v-for="collection in liveCollections"
              :key="collection._id"
              :collection="collection"
            />
          </section>
        </div>
        <div v-else>
          <div class="mt-md-5">
            <div class="empty-icon-bg collection mb-1">
              <message-icon />
            </div>
            <b-card-text
              class="color-sonic-silver"
            >The brand doesn’t have any active collections. You’ll get <br>
              notification, when the brand uploaded the new one.
            </b-card-text>
          </div>
        </div>
      </b-tab>
      <b-tab title="Previous Collections">
        <div v-if="previousCollections.length">
          <section class="brand-collections">
            <collection-card
              v-for="collection in previousCollections"
              :key="collection._id"
              :is-previous-collection="true"
              :collection="collection"
            />
          </section>
        </div>
        <div v-else>
          <div class="mt-md-5">
            <div class="empty-icon-bg collection mb-1">
              <message-icon-2 />
            </div>
            <b-card-text
              class="color-sonic-silver"
            >This brand doesn’t have any previous collections.
            </b-card-text>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <div
      v-else
      class="elements-center p-5 w-100"
    >
      <b-spinner />
    </div>
  </div>
</template>

<script>
import {
 BTabs, BTab, BSpinner, BCardText,
} from 'bootstrap-vue'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import { apiToastWarning } from '@/@core/utils/toast'
import MessageIcon from '@/@core/assets/svg-components/MessageIcon.vue'
import MessageIcon2 from '@/@core/assets/svg-components/MessageIcon2.vue'
import { GET_COLLECTION_LIST } from '@/store/modules/collection.module'
import CollectionCard from './CollectionCard.vue'

const { COLLECTION_STATUS } = constants

export default {
  components: {
    BTabs,
    BTab,
    BSpinner,
    BCardText,
    CollectionCard,
    MessageIcon,
    MessageIcon2,
  },
  data() {
    return {
      brandId: this.$route?.params?.brandId,
      liveCollections: [],
      previousCollections: [],
      isLoaded: false,
    }
  },
  watch: {
    '$route.params.brandId': {
      handler(brandId) {
        // route changes
        if (brandId !== this.brandId) {
          this.brandId = brandId
          this.fetchCollections()
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchCollections()
  },
  methods: {
    fetchCollections() {
      const params = {
        brandId: this.brandId,
      }
      this.$store.dispatch(GET_COLLECTION_LIST, params)
        .then(res => {
          const collections = res.data.data.collections
          this.liveCollections = collections.filter(
            col => col.status === COLLECTION_STATUS.LIVE.VALUE,
          )
          this.previousCollections = collections.filter(
            col => col.status === COLLECTION_STATUS.EXPIRED.VALUE,
          )
          this.isLoaded = true
        })
        .catch(err => {
          this.isLoaded = true
          apiToastWarning(err)
        })
    },
  },
}
</script>
