<template>
  <svg
    width="58"
    height="58"
    viewBox="0 0 58 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="29"
      cy="29"
      r="27.4854"
      stroke="#16ACD9"
      stroke-opacity="0.5"
      stroke-width="2.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.125 20.1388C40.7307 20.704 40.7634 21.6532 40.1981 22.2588L26.1981 37.2588C25.9206 37.5562 25.5341 37.7281 25.1274 37.7351C24.7207 37.7421 24.3285 37.5837 24.0409 37.296L17.0409 30.296C16.4551 29.7102 16.4551 28.7605 17.0409 28.1747C17.6267 27.5889 18.5764 27.5889 19.1622 28.1747L25.0644 34.0768L38.005 20.2119C38.5702 19.6063 39.5194 19.5735 40.125 20.1388Z"
      fill="#16ACD9"
    />
  </svg>
</template>
<script>
export default {
  name: 'CircleTickIcon',
}
</script>
