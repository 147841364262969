<template>
  <svg
    width="12"
    height="15"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0656 3.95844L12.7415 1.82549C12.9121 1.60836 12.8744 1.29403 12.6573 1.12342L12.3427 0.876294C12.1256 0.705687 11.8113 0.743406 11.6407 0.960542L10.2657 2.71048C9.34918 1.66209 8.00192 0.999873 6.49999 0.999874C3.73857 0.999877 1.5 3.23845 1.5 5.99987L1.5 8.99988L0.792893 9.70698C0.605357 9.89452 0.5 10.1489 0.5 10.4141V11.4999C0.5 12.3283 1.17157 12.9999 2 12.9999H2.18119L1.25849 14.1742C1.08788 14.3914 1.1256 14.7057 1.34274 14.8763L1.65726 15.1234C1.8744 15.294 2.18873 15.2563 2.35933 15.0392L3.96164 12.9999L11 12.9999C11.8284 12.9999 12.5 12.3283 12.5 11.4999L12.5 10.4141C12.5 10.1489 12.3946 9.89452 12.2071 9.70698L11.5 8.99988V5.99988C11.5 5.2727 11.3448 4.58177 11.0656 3.95844ZM9.37932 3.83862C8.72247 2.96493 7.67722 2.39987 6.49999 2.39987C4.51177 2.39988 2.9 4.01165 2.9 5.99987L2.9 9.57977L1.9 10.5798V11.4999C1.9 11.5551 1.94477 11.5999 2 11.5999H3.28119L9.37932 3.83862ZM5.06164 11.5999L10.0279 5.27924C10.0752 5.51207 10.1 5.75307 10.1 5.99988L10.1 9.57977L11.1 10.5798V11.4999C11.1 11.5551 11.0552 11.5999 11 11.5999H5.06164Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'BellOffIcon',
}
</script>
