<template>
  <div>
    <b-modal
      :id="`updateavailability_${collection._id}`"
      ref="updateavailability"
      size="lg"
      modal-class="modal-primary custom-model"
      centered
      hide-header-close
      hide-header
      title="Update Availability"
      hide-footer
      @show="updateDefaultSelectedOption"
    >
      <div>
        <div class="pb-50 border-bottom">
          <span class="custom-modal-heading">Update Availability </span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="closeModal"
          />
        </div>
        <p class="mt-1">
          Update availability dates for your products
          <b-button
            class="downloadsheet ml-1"
            variant="primary"
            @click="downloadCatalog"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                id="_id"
                icon="DownloadIcon"
                class="mr-1"
                size="20"
              />
              Download Catalog
            </div>
          </b-button>
        </p>
        <label
          v-if="!catalogFile"
          for="uploadCatalog"
          class="fileDownload elements-center"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="50"
            color="skyblue"
          />
          <h4 class="font-weight-bolder"><b>Drag & Drop</b></h4>
          <h6>or click to select files from your system</h6>
        </label>
        <div
          v-if="catalogFile"
          class="d-flex file-droped mt-50"
        >
          <b-img
            :src="xlsxIcon"
            class="mx-1"
            alt="ErrorIcon"
          />
          <span class="catalog-label name">Name: {{ catalogFile.name }} </span>
          <span
            class="catalog-label size"
          >Size: {{ getSizeInKb(catalogFile.size) }} KB</span>
          <div
            v-if="catalogFile"
            class="delete-file d-flex align-items-center justify-content-center"
            @click="catalogFile = null"
          >
            <feather-icon
              icon="TrashIcon"
              size="24"
              fill="white"
              color="white"
            />
          </div>
        </div>
        <b-form-file
          id="uploadCatalog"
          v-model="catalogFile"
          name="uploadCatalog"
          accept=".xlsx"
          :hidden="true"
          plain
        />
      </div>
      <div>
        <div class="text-center mt-1">
          <b-button
            type="reset"
            class="mt-2 mr-2 px-sm-3 font-weight-bolder"
            variant="outline-info"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            type="submit"
            variant="info"
            class="mt-2 px-sm-3 font-weight-bolder"
            :disabled="isSaving || (!catalogFileLink && !catalogFile)"
            @click="openOrdersModal"
          >
            {{
              confirmedOrders.length ? 'Next' : isSaving ? 'Saving...' : 'Save'
            }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="`selectorders_${collection._id}`"
      ref="selectorders"
      size="lg"
      modal-class="modal-primary select-orders"
      centered
      hide-header-close
      hide-header
      hide-footer
    >
      <div>
        <div class="pb-50 border-bottom">
          <span
            class="custom-modal-heading color-dark-black"
          >Updating Orders
          </span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="closeModal"
          />
        </div>
        <b-row class="mt-1">
          <b-col class="col-10">
            <b-input-group
              class="input-group-merge"
            >
              <b-form-input
                type="text"
                class="form-control-merge"
                placeholder="Search order id, retailer name..."
                @input="onSearchOrders"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  size="18"
                  class="cursor-pointer"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col class="col-2">
            <b-button
              class="w-100"
              variant="primary"
              @click="toggleSelectAllOrders"
            >
              {{ isSelectedOrders ? 'Deselect all' : 'Select all' }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="orders-modal-row-header">
          <b-col class="col-6">
            Order Id
          </b-col>
          <b-col class="col-6">
            Retailer Name
          </b-col>
        </b-row>
        <div class="orders-block scroll-bar">
          <b-form-checkbox-group
            v-model="selectedOrders"
            class="orders-checkbox"
          >
            <b-form-checkbox
              v-for="order in orderList"
              :key="order.details._id"
              :value="order.details._id"
            >
              <b-row class="w-100  cursor-pointer">
                <b-col class="col-6">
                  {{ order.details.seqId }}
                </b-col>
                <b-col class="col-6">
                  {{ order.details.retailerName }}
                </b-col>
              </b-row>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </div>
      <div>
        <div class="text-center">
          <b-button
            type="reset"
            class="mt-2 mr-2 px-sm-3 font-weight-bolder"
            variant="outline-info"
            @click="closeOrderModal"
          >
            Back
          </b-button>
          <b-button
            type="submit"
            variant="info"
            class="mt-2 px-sm-3 font-weight-bolder"
            :disabled="isSaving"
            @click="uploadCollection"
          >
            {{ isSaving ? 'Saving...' : 'Save' }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="`${collection._id}-price-book`"
      size="lg"
      modal-class="modal-primary custom-model"
      centered
      hide-header-close
      hide-header
      title="Price Books"
      hide-footer
      @hide="closePriceBook"
    >
      <div
        class="mb-1"
        :class="{'disable-whole-div': isExportingPriceBook}"
      >
        <div class="pb-50">
          <span class="custom-modal-heading">Price Books </span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="onClickClosePriceBook"
          />
        </div>
        <template v-if="!isImportingPriceBook">
          <div class="w-100 mt-1">
            <div class="w-100 d-flex justify-content-end">
              <b-button
                v-if="!showCreatePriceBook"
                variant="primary"
                class="font-weight-bolder"
                @click="showCreatePriceBook = !showCreatePriceBook"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="18"
                  class="align-text-bottom"
                />
                Create
              </b-button>
            </div>
            <div
              v-if="showCreatePriceBook"
              class="add-currency-container"
            >
              <span>Enter price book name</span>
              <b-form-input
                v-model="priceBookName"
                placeholder="Enter price book name"
                class="mt-75"
              />
              <div class="mt-1 d-flex justify-content-end">
                <b-button
                  variant="outline-primary"
                  class="mr-1 font-weight-bolder"
                  @click="cancelCreatePriceBook"
                >
                  Cancel
                </b-button>
                <b-button
                  variant="primary"
                  :disabled="isCreating || !priceBookName"
                  class="font-weight-bolder"
                  @click="createPriceBook"
                >
                  {{ isCreating ? 'Creating' : 'Create' }}
                </b-button>
              </div>
            </div>
          </div>

          <!-- Master price book -->
          <div
            class="mt-1"
          >
            <div class="border-bottom pr-75 py-75 font-inter font-weight-bold">
              Master Price Book
            </div>
            <div class="price-book-list">
              <div
                class="d-flex align-items-center justify-content-between border-bottom p-75"
              >
                <div>
                  Master Price Book
                </div>
                <div class="d-flex justify-content-end">
                  <b-button
                    class="font-weight-bold font-size-16-px flex-wrap elements-center mr-1"
                    variant="outline-primary"
                    @click="exportPriceBook()"
                  >
                    <b-spinner
                      v-if="isExportingPriceBook && !exportingPriceBookId"
                      class="mr-50"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="UploadIcon"
                      size="14"
                      class="mr-50"
                    />
                    Export </b-button>
                  <b-button
                    variant="outline-primary"
                    @click="onClickImportPriceBook()"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                      class="mr-50"
                    />
                    Import
                  </b-button>
                </div>
              </div>
            </div>
          </div>

          <!-- Price book list -->
          <div
            v-if="priceBookList.length"
            class="mt-1"
          >
            <div class="border-bottom pr-75 py-75 font-inter font-weight-bold">
              All Price Books({{ priceBookList.length }})
            </div>
            <div class="price-book-list scroll-bar">
              <div
                v-for="priceBook of priceBookList"
                :key="priceBook._id"
                class="w-100 d-flex align-items-center justify-content-between border-bottom p-75"
              >
                <div class="col-7 text-wrap">
                  {{ priceBook.priceBookName }}
                </div>
                <div class="d-flex justify-content-end">
                  <b-button
                    class="font-weight-bold font-size-16-px flex-wrap elements-center mr-1"
                    variant="outline-primary"
                    @click="exportPriceBook(priceBook)"
                  >
                    <b-spinner
                      v-if="isExportingPriceBook && exportingPriceBookId === priceBook._id"
                      class="mr-50"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="UploadIcon"
                      size="14"
                      class="mr-50"
                    />
                    Export </b-button>
                  <b-button
                    variant="outline-primary"
                    @click="onClickImportPriceBook(priceBook)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                      class="mr-50"
                    />
                    Import
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div>
            <p class="mt-1">
              Upload file for {{ selectedPriceBook ? selectedPriceBook.priceBookName : 'Master Price Book' }}
            </p>
            <label
              v-if="!priceBookFile"
              for="uploadCatalog"
              class="fileDownload elements-center"
            >
              <feather-icon
                icon="UploadCloudIcon"
                size="50"
                color="skyblue"
              />
              <h4 class="font-weight-bolder"><b>Drag & Drop</b></h4>
              <h6>or click to select files from your system</h6>
            </label>
            <div
              v-if="priceBookFile"
              class="d-flex file-droped mt-50"
            >
              <b-img
                :src="xlsxIcon"
                class="mx-1"
                alt="ErrorIcon"
              />
              <span class="catalog-label name">Name: {{ priceBookFile.name }} </span>
              <span
                class="catalog-label size"
              >Size: {{ getSizeInKb(priceBookFile.size) }} KB</span>
              <div
                v-if="priceBookFile"
                class="delete-file d-flex align-items-center justify-content-center"
                @click="priceBookFile = null"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="24"
                  fill="white"
                  color="white"
                />
              </div>
            </div>
            <b-form-file
              id="uploadCatalog"
              v-model="priceBookFile"
              name="uploadCatalog"
              accept=".xlsx"
              :hidden="true"
              plain
            />
          </div>
          <div class="elements-center">
            <b-button
              type="reset"
              class="mt-2 mr-2 px-sm-3 font-weight-bolder"
              variant="outline-info"
              :disabled="isUploading"
              @click="onClickBackPriceBook"
            >
              Back
            </b-button>
            <b-button
              type="submit"
              variant="info"
              class="mt-2 px-sm-3 font-weight-bolder"
              :disabled="isUploading || !priceBookFile"
              @click="importPriceBook"
            >
              {{ isUploading ? 'Uploading...' : 'Upload' }}
            </b-button>
          </div>
        </template>
      </div>
    </b-modal>

    <!-- Currency book modal -->
    <b-modal
      :id="`${collection._id}_currency_book`"
      size="lg"
      modal-class="modal-primary custom-model"
      centered
      hide-header-close
      hide-header
      title="Currencies"
      hide-footer
      @hide="resetCurrencyInput"
    >
      <template v-if="!selectedCurrencyForImport">
        <div>
          <div class="pb-50">
            <span class="custom-modal-heading">Currencies </span>
            <feather-icon
              class="float-right close-x-icon"
              icon="XIcon"
              size="24"
              @click="closeModal"
            />
          </div>
          <!-- Body -->
          <div
            class="w-100 mt-1 mb-1"
            :class="{'disable-whole-div': isExportingCurrency}"
          >
            <div class="w-100">
              <b-button
                v-if="!showAddCurrencyField"
                class="font-weight-bold font-size-16-px flex-wrap elements-center ml-auto"
                variant="custom-primary"
                @click="showAddCurrencyField=true"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="20"
                  class="mr-25"
                />
                Add new
              </b-button>
              <div
                v-else
                class="add-currency-container"
              >
                <div class="text-primary font-weight-bold">
                  Add new currency
                </div>
                <div class="mt-1">
                  <b-dropdown
                    class="filter-dropdown"
                    variant="outline-none"
                    block
                    no-caret
                    menu-class="w-100 filter-dropdown-menu scroll-bar"
                    toggle-class="filter-dropdown-toggle"
                    no-flip
                  >
                    <template #button-content>
                      <div
                        class="d-flex justify-content-between"
                        :class="{ 'has-value': !!selectedCurrency }"
                      >
                        <span> {{ selectedCurrency || 'Select currency' }} </span>
                        <feather-icon icon="ChevronDownIcon" />
                      </div>
                    </template>
                    <b-dropdown-text>
                      <b-form-input
                        placeholder="Search"
                        class="filter-dropdown-toggle"
                        @input="searchCurrency"
                      />
                      <div
                        v-if="!currencies.length"
                        class="py-2 text-center"
                      >
                        Nothing was found. Try another currency.
                      </div>
                    </b-dropdown-text>
                    <b-dropdown-item
                      v-for="name in currencies"
                      :key="name"
                      :active="selectedCurrency === name"
                      @click="onSelectCurrency(name)"
                    >
                      <div>
                        <span>
                          {{ name }}
                        </span>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="w-100 d-flex justify-content-end mt-1">
                  <b-button
                    class="font-weight-bold font-size-16-px flex-wrap elements-center mr-1"
                    variant="outline-primary"
                    :disabled="isSavingCurrency"
                    @click="showAddCurrencyField=false"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    class="font-weight-bold font-size-16-px flex-wrap elements-center"
                    variant="custom-primary"
                    :disabled="!selectedCurrency || isSavingCurrency"
                    @click="onAddCurrency"
                  >
                    <b-spinner
                      v-if="isSavingCurrency"
                      class="mr-50"
                      small
                    />
                    <span v-else>
                      Add
                    </span>
                  </b-button>
                </div>

              </div>
            </div>

            <!-- Master Currency Book -->
            <div class="mt-1">
              <div class="font-inter font-weight-bold">
                Master Currency Book
              </div>
              <div class="border-top border-bottom d-flex pt-50 pb-50 mt-50">
                <div class="col-4 d-flex align-items-center">
                  <span class="text-secondary text-nowrap mr-5-px">Base Currency:</span> {{ baseCurrency }}
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <b-button
                    class="font-weight-bold font-size-16-px flex-wrap elements-center mr-1"
                    variant="outline-primary"
                    @click="exportCurrencies()"
                  >
                    <b-spinner
                      v-if="isExportingCurrency && !exportingCurrency"
                      class="mr-50"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="UploadIcon"
                      size="14"
                      class="mr-50"
                    />
                    Export
                  </b-button>
                  <b-button
                    class="font-weight-bold font-size-16-px flex-wrap elements-center"
                    variant="outline-primary"
                    @click="onClickImportCurrency(baseCurrency)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                      class="mr-50"
                    />
                    Import
                  </b-button>
                </div>
              </div>
            </div>

            <!-- File currencies -->
            <div
              v-if="currencyFileList.length"
              class="mt-1"
            >
              <div class="font-inter font-weight-bold">
                Additional Currencies({{ currencyFileList.length }})
              </div>
              <div
                v-for="currency of currencyFileList"
                :key="currency"
                class="border-top d-flex pt-50 mt-50"
              >
                <div class="col-4 d-flex align-items-center">
                  {{ currency }}
                </div>
                <div class="col-8 d-flex justify-content-end">
                  <b-button
                    class="font-weight-bold font-size-16-px flex-wrap elements-center mr-1"
                    variant="outline-primary"
                    @click="exportCurrencies(currency)"
                  >
                    <b-spinner
                      v-if="isExportingCurrency && exportingCurrency === currency"
                      class="mr-50"
                      small
                    />
                    <feather-icon
                      v-else
                      icon="UploadIcon"
                      size="14"
                      class="mr-50"
                    />
                    Export
                  </b-button>
                  <b-button
                    class="font-weight-bold font-size-16-px flex-wrap elements-center"
                    variant="outline-primary"
                    @click="onClickImportCurrency(currency)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="14"
                      class="mr-50"
                    />
                    Import
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div :class="{'disable-whole-div' : isUploadingCurrencyBook}">
          <div>
            <p class="mt-1">
              Upload file for {{ selectedCurrencyForImport }}
            </p>
            <label
              v-if="!currencyBookFile"
              for="uploadCatalog"
              class="fileDownload elements-center"
            >
              <feather-icon
                icon="UploadCloudIcon"
                size="50"
                color="skyblue"
              />
              <h4 class="font-weight-bolder"><b>Drag & Drop</b></h4>
              <h6>or click to select files from your system</h6>
            </label>
            <div
              v-if="currencyBookFile"
              class="d-flex file-droped mt-50"
            >
              <b-img
                :src="xlsxIcon"
                class="mx-1"
                alt="ErrorIcon"
              />
              <span class="catalog-label name">Name: {{ currencyBookFile.name }} </span>
              <span
                class="catalog-label size"
              >Size: {{ getSizeInKb(currencyBookFile.size) }} KB</span>
              <div
                v-if="currencyBookFile"
                class="delete-file d-flex align-items-center justify-content-center"
                @click="currencyBookFile = null"
              >
                <feather-icon
                  icon="TrashIcon"
                  size="24"
                  fill="white"
                  color="white"
                />
              </div>
            </div>
            <b-form-file
              id="uploadCatalog"
              v-model="currencyBookFile"
              name="uploadCatalog"
              accept=".xlsx"
              :hidden="true"
              plain
            />
          </div>
          <div class="elements-center">
            <b-button
              type="reset"
              class="mt-2 mr-2 px-sm-3 font-weight-bolder"
              variant="outline-info"
              :disabled="isUploadingCurrencyBook"
              @click="resetCurrencyInput"
            >
              Back
            </b-button>
            <b-button
              type="submit"
              variant="info"
              class="mt-2 px-sm-3 font-weight-bolder"
              :disabled="isUploadingCurrencyBook || !currencyBookFile"
              @click="importCurrencyBookFile"
            >
              {{ isUploadingCurrencyBook ? 'Uploading...' : 'Upload' }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BImg,
  BFormFile,
  BModal,
  BFormCheckboxGroup,
  BFormCheckbox,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BDropdown,
  BDropdownText,
  BDropdownItem,
  BSpinner,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import store from '@/store'
import {
  CREATE_PRICE_BOOK,
  EXPORT_CURRENCIES,
  GET_PRICE_BOOK,
  IMPORT_CURRENCIES,
  IMPORT_PRICE_BOOK,
  UPDATE_COLLECTION,
  UPDATE_CURRENCIES,
} from '@/store/modules/collection.module'
import {
  getSizeInKb, formatObject, parseErrorObject,
} from '@/@core/utils/utils'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { UPLOAD_DOCUMENTS, DOWNLOAD_FILE } from '@/store/modules/common.module'
import analytics, { getCollectionMixpanelObj } from '@/@core/utils/analytics'
import constants, { availableCurrencies, FILE_FORMATS } from '@/constants'
import { CONFIRMED_ORDERS } from '@/store/modules/order.module'
import { kpEndpoint, kpParams } from '@/axios'

const { FILE_TYPES } = c

const { MASTER_PRICE_BOOK } = constants.TRACKS.ACTIONS

export default {
  components: {
    BButton,
    BImg,
    BFormFile,
    BModal,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,
    FeatherIcon,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownText,
    BDropdownItem,
    BSpinner,
  },
  props: {
    collection: {
      type: Object,
      default: () => {},
    },
    fetchCollections: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      orderList: [],
      confirmedOrders: [],
      selectedOrders: [],
      xlsxIcon: require('@/assets/images/icons/xlsxIcon.svg'),
      collectionId: this.collection._id,
      catalogFile: null,
      catalogFileLink: null,
      isSaving: false,
      getSizeInKb,
      showCreatePriceBook: false,
      priceBookName: '',
      isCreating: false,
      priceBookList: [],
      isImportingPriceBook: false,
      selectedPriceBook: null,
      priceBookFile: null,
      isUploading: false,
      showAddCurrencyField: false,
      selectedCurrency: null,
      currencyFileList: [],
      currencies: availableCurrencies || [],
      isExportingCurrency: false,
      exportingCurrency: '',
      currencyBookFile: null,
      selectedCurrencyForImport: '',
      isUploadingCurrencyBook: false,
      isSavingCurrency: false,
      isExportingPriceBook: false,
      exportingPriceBookId: null,
    }
  },
  computed: {
    isSelectedOrders() {
      return !!this.selectedOrders.length
    },
    baseCurrency() {
      return this.$store.getters.profile?.currency
    },
  },
  watch: {
    priceBookFile: {
      handler(val) {
        if (!val) {
          return
        }
        analytics.track(MASTER_PRICE_BOOK.BRAND_SELECTS_MASTER_PRICE_BOOK_FILE, val)
      },
      deep: true,
    },
  },
  methods: {
    resetCurrencyInput() {
      this.selectedCurrency = null
      this.showAddCurrencyField = false
      this.selectedCurrencyForImport = ''
      this.currencyBookFile = null
    },
    async exportCurrencies(currency = '') {
      this.isExportingCurrency = true
      this.exportingCurrency = currency

      const params = {
        collectionId: this.collection._id,
      }
      let fileName = ''
      if (currency) {
        params.currency = currency
        fileName = `${this.collection.name}-${currency}-currency-book.xlsx`
      } else {
        fileName = `${this.collection.name}-master-currency-book.xlsx`
      }

      const payload = {
          url: await this.$store.dispatch(EXPORT_CURRENCIES, params),
          fileName,
          fileFormat: FILE_FORMATS.XLSX,
          successMessage: 'Currency book exported successfully',
        }
      this.$store.dispatch(DOWNLOAD_FILE, payload).then(() => {
        this.isExportingCurrency = false
        this.exportingCurrency = ''
      }).catch(err => {
        apiToastWarning(err)
        this.isExportingCurrency = false
        this.exportingCurrency = ''
      })
    },
    onAddCurrency() {
      if (!this.selectedCurrency) {
        return
      }
      if (this.currencyFileList.includes(this.selectedCurrency) || this.selectedCurrency === this.baseCurrency) {
        apiToastWarning('Currency already added')
        return
      }
      this.isSavingCurrency = true
      const payload = {
        currencies: [...this.currencyFileList, this.selectedCurrency],
      }
      this.$store.dispatch(UPDATE_CURRENCIES, { collectionId: this.collection._id, payload }).then(res => {
        apiToastSuccess(res.data.message)
        this.isSavingCurrency = false
        this.currencyFileList.push(this.selectedCurrency)
        this.resetCurrencyInput()
        this.fetchCollections()
      }).catch(err => {
        this.isSavingCurrency = false
        apiToastWarning(err)
      })
    },
    searchCurrency(searchString) {
      const searchRegex = new RegExp(searchString, 'i')
      this.currencies = availableCurrencies.filter(currency => searchRegex.test(currency))
    },
    onSelectCurrency(currency) {
      if (this.selectedCurrency === currency) {
        this.selectedCurrency = null
        return
      }
      this.selectedCurrency = currency
    },
    onClickImportCurrency(currency = '') {
      this.selectedCurrencyForImport = currency
    },
    async importCurrencyBookFile() {
      this.isUploadingCurrencyBook = true
      const fileUrl = await this.$store.dispatch(UPLOAD_DOCUMENTS, { file: this.currencyBookFile, fileType: FILE_TYPES.MULTICURRENCY_BOOK })
      const params = {
        collectionId: this.collection._id,
      }
      if (this.selectedCurrencyForImport && this.selectedCurrencyForImport !== this.baseCurrency) {
        params.currency = this.selectedCurrencyForImport
      }
      this.$store.dispatch(IMPORT_CURRENCIES, { params, payload: { fileUrl } }).then(res => {
        apiToastSuccess(res.data.message)
        this.isUploadingCurrencyBook = false
        this.currencyBookFile = null
        this.selectedCurrencyForImport = ''
      }).catch(err => {
        apiToastWarning(err)
        this.isUploadingCurrencyBook = false
      })
    },
    toggleSelectAllOrders() {
      if (this.isSelectedOrders) {
        this.selectedOrders = []
        return
      }
      this.selectedOrders = this.orderList.map(order => order.details._id)
    },
    onSearchOrders(searchString = '') {
  const searchRegex = new RegExp(searchString, 'i')
  this.orderList = this.confirmedOrders.filter(order => searchRegex.test(order?.details?.seqId) || searchRegex.test(order?.details?.retailerName))
},
    closeModal() {
      this.catalogFile = null
      this.catalogFileLink = null
      this.$bvModal.hide(`updateavailability_${this.collection._id}`)
      this.$bvModal.hide(`selectorders_${this.collection._id}`)
      // Hide currency book modal
      this.$bvModal.hide(`${this.collection._id}_currency_book`)
    },

    updateDefaultSelectedOption() {
      this.getConfirmedOrders()
    },
    downloadCatalog() {
      const payload = {
        url: kpParams(kpEndpoint.catalog.downloadProductAvailabilityFile, this.collection._id),
        fileName: `${this.collection.name}.xlsx`,
        fileFormat: FILE_FORMATS.XLSX,
      }
      store.dispatch(DOWNLOAD_FILE, payload)
    },
    openOrdersModal() {
      if (this.confirmedOrders.length) {
        this.$bvModal.hide(`updateavailability_${this.collection._id}`)
        this.$bvModal.show(`selectorders_${this.collection._id}`)
      } else {
        this.uploadCollection()
      }
    },
    async uploadCollection() {
      this.isSaving = true
      if (this.catalogFile && this.collectionId) {
        const payload = {
          productAvailabilityFile: await this.$store.dispatch(UPLOAD_DOCUMENTS, { file: this.catalogFile, fileType: FILE_TYPES.COLLECTION_PRODUCTS_DETAILED }),
          orderIds: this.selectedOrders,
        }
        store
          .dispatch(UPDATE_COLLECTION, {
            collectionId: this.collection._id,
            payload,
          })
          .then(res => {
            apiToastSuccess(res.data.message)
            this.isSaving = false
            this.closeModal()
            analytics.track(
              constants.TRACKS.ACTIONS.BRAND_ENDS_UPDATING_AVAILABILITY_DATES,
              formatObject(
                getCollectionMixpanelObj(this.collection, {
                  updateAvailabilityStatus: 'Success',
                }),
              ),
            )
          })
          .catch(err => {
            this.isSaving = false
            apiToastWarning(err)
            analytics.track(
              constants.TRACKS.ACTIONS.BRAND_ENDS_UPDATING_AVAILABILITY_DATES,
              formatObject(
                getCollectionMixpanelObj(this.selectedCollection, {
                  updateAvailabilityStatus: 'Error',
                  error: parseErrorObject(err),
                }),
              ),
            )
          })
      } else {
        this.isSaving = false
        apiToastWarning('Please Select File')
      }
    },
    closeOrderModal() {
      this.$bvModal.hide(`selectorders_${this.collection._id}`)
      this.$bvModal.show(`updateavailability_${this.collection._id}`)
    },
    getConfirmedOrders() {
      store.dispatch(CONFIRMED_ORDERS, this.collection._id).then(res => {
        this.confirmedOrders = res.data.data
        this.orderList = this.confirmedOrders
      })
    },
    async getCollectionPriceBook(shouldOpenPopup = false) {
      try {
        const reusult = await this.$store.dispatch(GET_PRICE_BOOK, this.collectionId)
        this.priceBookList = reusult.data?.data || []
        if (shouldOpenPopup) {
          this.$bvModal.show(`${this.collection._id}-price-book`)
          this.showCreatePriceBook = !this.priceBookList.length
        }
      } catch (err) {
        apiToastWarning(err)
      }
    },
    openCurrencyBook() {
      this.currencyFileList = this.collection?.availableCurrencies || []
      this.$bvModal.show(`${this.collection._id}_currency_book`)
    },
    async createPriceBook() {
      this.isCreating = true
      try {
        const payload = {
          priceBookName: this.priceBookName,
        }
        const result = await this.$store.dispatch(CREATE_PRICE_BOOK, { payload, collectionId: this.collectionId })
        this.getCollectionPriceBook()
        this.priceBookName = ''
        this.showCreatePriceBook = false
        apiToastSuccess(result.data.message || 'Price book created successfully')
      } catch (err) {
        apiToastWarning(err)
      } finally {
        this.isCreating = false
      }
    },
    cancelCreatePriceBook() {
      if (this.priceBookList.length) {
        this.showCreatePriceBook = false
      }
      else {
        this.$bvModal.hide(`${this.collectionId}-price-book`)
      }
    },
    exportPriceBook(priceBook = null) {
      if (!priceBook) {
        analytics.track(MASTER_PRICE_BOOK.BRAND_CLICKS_EXPORT_MASTER_PRICE_BOOK)
      }
      this.isExportingPriceBook = true
      this.exportingPriceBookId = priceBook?._id
      let payload = null
      payload = priceBook ? {
        url: this.$kpParams(this.$kpEndpoint.catalog.pricebook.export, this.collectionId, priceBook._id),
        fileName: `${priceBook?.priceBookName}-price-book.xlsx`,
        fileFormat: FILE_FORMATS.XLSX,
        successMessage: 'Price book exported successfully',
      } : {
        url: this.$kpParams(this.$kpEndpoint.catalog.pricebook.exportMasterFile, this.collectionId),
        fileName: `${this.collection?.name}-master-price-book.xlsx`,
        fileFormat: FILE_FORMATS.XLSX,
        successMessage: 'Master price book exported successfully',
      }
      this.$store.dispatch(DOWNLOAD_FILE, payload).then(res => {
        analytics.track(MASTER_PRICE_BOOK.MASTER_PRICE_BOOK_EXPORTED_SUCCESSFULLY, { fileObject: res })
      }).catch(err => {
        analytics.track(MASTER_PRICE_BOOK.MASTER_PRICE_BOOK_EXPORT_FAILED, { error: parseErrorObject(err) })
      }).finally(() => {
        this.isExportingPriceBook = false
        this.exportingPriceBookId = null
      })
    },
    onClickImportPriceBook(priceBook = null) {
      this.selectedPriceBook = priceBook
      this.isImportingPriceBook = true
      if (!priceBook) {
        analytics.track(MASTER_PRICE_BOOK.BRAND_CLICKS_IMPORT_BTN_TO_OPEN_FILE_UPLOAD_POPUP)
      }
    },
    onClickBackPriceBook() {
      this.selectedPriceBook = null
      this.isImportingPriceBook = false
      this.priceBookFile = null
      analytics.track(MASTER_PRICE_BOOK.BRAND_CLICKS_BACK_BTN_ON_FILE_UPLOAD_POPUP)
    },
    async importPriceBook() {
      analytics.track(MASTER_PRICE_BOOK.BRAND_CLICKS_IMPORT_BTN_ON_FILE_UPLOAD_POPUP, this.priceBookFile)
      this.isUploading = true
      try {
        const fileLink = await this.$store.dispatch(UPLOAD_DOCUMENTS, { fileType: FILE_TYPES.PRICE_BOOK, file: this.priceBookFile })
        const payloadObject = {
          collectionId: this.collection?._id,
          payload: {
            priceBookFile: fileLink,
          },
        }
        if (this.selectedPriceBook?._id) {
          payloadObject.priceBookId = this.selectedPriceBook?._id
        }
        const result = await this.$store.dispatch(IMPORT_PRICE_BOOK, payloadObject)
        apiToastSuccess(result.data.message)
        this.isImportingPriceBook = false
        this.priceBookFile = null
        this.selectedPriceBook = null
        analytics.track(MASTER_PRICE_BOOK.MASTER_PRICE_BOOK_IMPORTED_SUCCESSFULLY, { importedFile: fileLink, collection: this.collection })
      } catch (err) {
        apiToastWarning(err)
        analytics.track(MASTER_PRICE_BOOK.MASTER_PRICE_BOOK_IMPORT_FAILED, { error: parseErrorObject(err) })
      }
      finally {
        this.isUploading = false
      }
    },
    closePriceBook() {
      this.selectedPriceBook = null
      this.isUploading = false
      this.isCreating = false
      this.priceBookName = null
      this.isImportingPriceBook = false
      this.priceBookFile = null
    },
    onClickClosePriceBook() {
      if (this.isImportingPriceBook) {
        this.onClickBackPriceBook()
        return
      }
      this.$bvModal.hide(`${this.collection._id}-price-book`)
    },
  },
}
</script>

<style lang="scss">
.add-currency-container {
  padding: 10px;
  background: #DFDFDFB2;
  border-radius: 5px;
}
</style>
