<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.51785 8.45655L3.512 8.4519L3.5061 8.4473C2.54093 7.69622 1.99998 6.57852 1.99998 5.36957C1.99998 3.21108 3.80991 1.4 5.99998 1.4C8.19004 1.4 9.99998 3.21108 9.99998 5.36957C9.99998 6.57852 9.45902 7.69622 8.49385 8.4473L8.48795 8.4519L8.4821 8.45655L8.05579 8.79568L7.62947 9.13481L7.62945 9.13478L7.62121 9.14144C7.54874 9.19998 7.47125 9.26108 7.39003 9.3251L7.38995 9.32516C6.96798 9.65782 6.44566 10.0696 6.00736 10.614C5.50874 9.98163 4.89012 9.52093 4.39987 9.15584L4.35331 9.12115L3.94417 8.79568L3.51785 8.45655ZM6.73131 12.0653L6.74548 12.0352C7.05482 11.3894 7.62958 10.929 8.2862 10.403C8.35694 10.3464 8.42862 10.2889 8.50103 10.2304L9.35366 9.55217C10.661 8.53478 11.4 7.0087 11.4 5.36957C11.4 2.43044 8.95576 0 5.99998 0C3.04419 0 0.599976 2.43044 0.599976 5.36957C0.599976 7.0087 1.33892 8.53478 2.64629 9.55217L3.49892 10.2304C4.24415 10.7862 4.91305 11.304 5.25543 12.0327L5.26962 12.0634C5.30096 12.1325 5.32938 12.2035 5.35467 12.2766L5.36454 12.3057C5.39052 12.3839 5.41294 12.4645 5.43155 12.5478C5.4884 12.8304 5.71576 13 5.99998 13C6.28419 13 6.51155 12.8304 6.5684 12.5478C6.5871 12.4641 6.60964 12.3835 6.63578 12.3056L6.64577 12.2765C6.6712 12.2038 6.69979 12.1335 6.73131 12.0653ZM5.99998 7.89994C7.32546 7.89994 8.39998 6.82542 8.39998 5.49994C8.39998 4.17446 7.32546 3.09994 5.99998 3.09994C4.67449 3.09994 3.59998 4.17446 3.59998 5.49994C3.59998 6.82542 4.67449 7.89994 5.99998 7.89994ZM6.99998 5.49994C6.99998 6.05222 6.55226 6.49994 5.99998 6.49994C5.44769 6.49994 4.99998 6.05222 4.99998 5.49994C4.99998 4.94765 5.44769 4.49994 5.99998 4.49994C6.55226 4.49994 6.99998 4.94765 6.99998 5.49994Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
    name: 'LocationIcon',
}
</script>
