<template>
  <div>
    <b-modal
      id="schedule_collection"
      modal-class="modal-schedule-collection"
      header-bg-variant="white"
      centered
      header-class="d-flex align-items-center"
      hide-footer
      size="lg"
    >
      <div class="modal-schedule-collection-body">
        <div class="modal-schedule-header">
          Collection schedule
        </div>
        <div class="mt-10-px mb-1 brand-name">
          {{ user.entityName }}
        </div>
        <template v-if="isRetailer">
          <div
            v-if="!isSubscribed"
            class="sub-title"
          >
            Follow the brand and you’ll be notified as soon as the latest
            collection goes live
          </div>
          <div
            v-else
            class="sub-title"
          >
            You will receive notifications about all new collections.
          </div>
        </template>
        <div class="months-container">
          <div
            v-for="month in monthsWithCollection"
            :key="month.LABEL"
          >
            <div
              class="month-item"
              :class="{ 'has-value': month.seasonType }"
            >
              <div class="header">
                {{ month.TEXT }}
              </div>
              <div class="body">
                <div class="w-100">
                  {{ SCHEDULED_COLLECTION_TYPES[month && month.seasonType] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-center mt-1 mb-1">
          <b-button
            v-if="!isSubscribed && isRetailer"
            variant="custom-primary"
            class="d-flex align-items-center"
            @click="$emit('onHandleSubscription')"
          >
            <bell-icon />
            <span class="ml-10-px"> Follow Brand </span>
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import BellIcon from '@/@core/assets/svg-components/BellIcon.vue'
import UserRoleMixinVue from '../../UserRoleMixin.vue'

const {
 MONTHS,
  SCHEDULED_COLLECTION_TYPE,
} = c

export default {
  components: {
    BModal,
    BellIcon,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [UserRoleMixinVue],
  props: {
    user: {
      type: Object,
      default() {
        return {}
      },
    },
    monthsWithCollection: {
      type: Array,
      default: () => [],
    },
    isSubscribed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      MONTHS,
      scheduledCollectionId: null,
    }
  },
  computed: {
    SCHEDULED_COLLECTION_TYPES() {
      const types = {}
      Object.values(SCHEDULED_COLLECTION_TYPE).forEach(type => {
        types[type.LABEL] = type.TEXT
      })
      return types
    },
  },
  emits: ['onHandleSubscription'],
}
</script>
